import { Translate } from '@mui/icons-material';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { Fragment } from 'react';

import { ILocalization, ILocalizationToken } from '../../../../../../../../application/language/ILocalization';
import { TranslateText } from '../../../../../../../../application/language/TranslateText';
import { inject } from '../../../../../../../../common/container/inject';
import translate from '../../../../../../../../locales/ru/translation';
import { IActiveDirectoryServerEntityService } from '../../../../../../../../service/activeDirectory/server/IActiveDirectoryServerEntityService';
import { IRowContextMenuUI } from '../../../../../../../design/dataTable/parts/rowContextMenu/domain/IRowContextMenuUI';
import { RowContextMenuDomain } from '../../../../../../../design/dataTable/parts/rowContextMenu/domain/RowContextMenuDomain';
import { ProjectLink } from '../../../../../../../design/link/ProjectLink';
import { ConfirmModal } from '../../../../../../../design/modal/confirm/ConfirmModal';
import { IMainLayoutDomainStore } from '../../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { ActiveDirectoryAdminModel } from './../ActiveDirectoryAdminModel';
import { ActiveDirectoryAdminDomain } from '../ActiveDirectoryAdminDomain';
import { ActiveDirectoryContextMenuUI } from './ActiveDirectoryContextMenuUI';

export class ActiveDirectoryContextMenuDomain extends RowContextMenuDomain<
  ActiveDirectoryAdminModel,
  IActiveDirectoryServerEntityService
> {
  constructor(
    {
      entityService,
      dataTableDomain,
      layoutDomain,
      ui,
    }: {
      entityService: IActiveDirectoryServerEntityService;
      dataTableDomain: ActiveDirectoryAdminDomain;
      layoutDomain: IMainLayoutDomainStore;
      ui?: IRowContextMenuUI<ActiveDirectoryAdminModel> | null;
    },
    public i18n: ILocalization = inject<ILocalization>(ILocalizationToken),
  ) {
    super(entityService, dataTableDomain, layoutDomain, ui);
    this.ui = ui || new ActiveDirectoryContextMenuUI(this);
  }

  async deleteADConfirmation(row: any) {
    //@ts-ignore
    const connectedProjects = await this.entityService.tryToDeleteADConnection(row.id);

    if (connectedProjects.length === 0) {
      const Component = () => {
        return (
          <ConfirmModal
            title={`${this.i18n.translate('ad.deleteRowModal.title')}?`}
            text={
              <div>
                <div>{this.i18n.translate('ad.deleteRowModal.message')}</div>
                <div>
                  {this.i18n.translate('ad.deleteRowModal.confirmationMessage', { name: row?.name || row?.row?.name })}
                </div>
              </div>
            }
            onCancel={async () => {
              this.layoutDomain.modalWindow.closeModalWindow();
            }}
            onOk={async () => {
              await this.deleteById(row.id);
              await this.dataTableDomain.boot();
              //   @ts-ignore
              this.dataTableDomain.modalDomain.ui.model.setEntity({});
              this.layoutDomain.modalWindow.closeModalWindow();
            }}
          />
        );
      };

      return Component;
    } else {
      const Component = () => {
        return (
          <Fragment>
            <DialogTitle id="alert-dialog-title" style={{ fontSize: '24px' }}>
              <TranslateText i18nKey={'ad.unableToDeleteRowModal.title'} />
            </DialogTitle>
            <DialogTitle id="alert-dialog-title" style={{ fontSize: '16px', paddingTop: 0 }}>
              <TranslateText i18nKey={'ad.unableToDeleteRowModal.message1'} options={{ name: 'row.name' }} />
            </DialogTitle>
            <DialogTitle id="alert-dialog-title" style={{ fontSize: '16px', paddingTop: 0 }}>
              <TranslateText i18nKey={'ad.unableToDeleteRowModal.message2'} />
            </DialogTitle>
            <DialogContent>
              {connectedProjects.map((item: any) => {
                return (
                  <DialogContentText id="alert-dialog-description" key={`${item.user.id}`}>
                    {item.user.displayName} {this.i18n.translate('ad.unableToDeleteRowModal.inProjects')}:{' '}
                    {item.projects.map((project: any, index: number) => (
                      <React.Fragment key={project.id}>
                        {index > 0 && ', '}
                        <ProjectLink
                          onClick={() => this.layoutDomain.modalWindow.closeModalWindow()}
                          url={`/project/${project.id}/info`}
                        >
                          {project.name}
                        </ProjectLink>
                      </React.Fragment>
                    ))}
                  </DialogContentText>
                );
              })}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.layoutDomain.modalWindow.closeModalWindow()} color="secondary">
                Ок
              </Button>
            </DialogActions>
          </Fragment>
        );
      };

      return Component;
    }
  }

  async onDeleteRow(row: ActiveDirectoryAdminModel) {
    const confirmComponent = await this.deleteADConfirmation(row);
    this.layoutDomain.modalWindow.showModalWindow(confirmComponent);
    const activeUrl = this.router.getActiveUrl();
    this.router.goTo(activeUrl);
  }

  async deleteById(rowId: string): Promise<void> {
    await super.deleteById(rowId);
    await this.dataTableDomain.loadList();
  }
}
